// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui'
import util from './libs/util'
import 'element-ui/packages/theme-chalk/lib/index.css'
import './assets/style/app.scss'
import store from './store'
import CKEditor from '@ckeditor/ckeditor5-vue2'

Vue.use(CKEditor)
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$util = util

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>',
  store
})
