// 本地存储key前缀
export const STORAGE_SUFFIX = 'basenbyte_admin'
// 系统名称
export const SITE_NAME = process.env.VUE_APP_NAME
// cookie过期时间, 天
export const COOKIE_EXPIRES = 2
// 接口的base_url
export const BASE_URL = '/api.php'
// 分页大小
export const PAGE_SIZE = 15
// 分页布局
export const PAGE_LAYOUT = 'prev, pager, next, total'
