import request from '@/request'
/**
 * 登录
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const sign = (data) => {
  return request.post('/sign', data)
}
/**
 * 登录用户信息
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userInfo = (params) => {
  return request.get('/user/info', {
    params
  })
}
/**
 * 修改用户密码
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const password = (data) => {
  return request.post('/user/password', data)
}
/**
 * 退出登录
 * @returns {Promise<AxiosResponse<any>>}
 */
export const logout = () => {
  return request.get('/user/logout', {})
}
