/**
 * 后台布局页面
 */
export default {
  path: '/dashboard',
  component: () => import('_v/layout.vue'),
  children: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('_v/dashboard/index.vue'),
      meta: {
        title: '首页'
      }
    },
    {
      path: '/password',
      name: 'password',
      component: () => import('_v/common/password.vue'),
      meta: {
        title: '修改密码'
      }
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('_v/news/index.vue'),
      meta: {
        title: '新闻管理',
        bread: []
      }
    },
    {
      path: '/news/create',
      name: 'newsCreate',
      component: () => import('_v/news/create.vue'),
      meta: {
        title: '发布新闻',
        bread: [
          { name: 'news', title: '新闻管理' }
        ]
      }
    },
    {
      path: '/news/edit',
      name: 'newsEdit',
      component: () => import('_v/news/edit.vue'),
      meta: {
        title: '编辑新闻',
        bread: [
          { name: 'news', title: '新闻管理' }
        ]
      }
    },
    {
      path: '/team',
      name: 'team',
      component: () => import('_v/team/index.vue'),
      meta: {
        title: '团队管理',
        bread: []
      }
    },
    {
      path: '/team/create',
      name: 'teamCreate',
      component: () => import('_v/team/create.vue'),
      meta: {
        title: '新增团队成员',
        bread: [
          { name: 'team', title: '团队管理' }
        ]
      }
    },
    {
      path: '/team/edit',
      name: 'teamEdit',
      component: () => import('_v/team/edit.vue'),
      meta: {
        title: '编辑团队成员',
        bread: [
          { name: 'team', title: '团队管理' }
        ]
      }
    },
    {
      path: '/partner',
      name: 'partner',
      component: () => import('_v/partner/index.vue'),
      meta: {
        title: '合作伙伴',
        bread: []
      }
    },
    {
      path: '/partner/create',
      name: 'partnerCreate',
      component: () => import('_v/partner/create.vue'),
      meta: {
        title: '新增合作伙伴',
        bread: [
          { name: 'partner', title: '合作伙伴' }
        ]
      }
    },
    {
      path: '/partner/edit',
      name: 'partnerEdit',
      component: () => import('_v/partner/edit.vue'),
      meta: {
        title: '编辑合作伙伴',
        bread: [
          { name: 'partner', title: '合作伙伴' }
        ]
      }
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('_v/contact/index.vue'),
      meta: {
        title: '联系我们',
        bread: []
      }
    },
    {
      path: '/jobs',
      name: 'jobs',
      component: () => import('_v/jobs/index.vue'),
      meta: {
        title: '职位管理',
        bread: []
      }
    },
    {
      path: '/jobs/create',
      name: 'jobsCreate',
      component: () => import('_v/jobs/create.vue'),
      meta: {
        title: '新增职位',
        bread: [
          { name: 'jobs', title: '职位管理' }
        ]
      }
    },
    {
      path: '/jobs/edit',
      name: 'jobsEdit',
      component: () => import('_v/jobs/edit.vue'),
      meta: {
        title: '编辑职位',
        bread: [
          { name: 'jobs', title: '职位管理' }
        ]
      }
    },
    {
      path: '/resume',
      name: 'resume',
      component: () => import('_v/resume/index.vue'),
      meta: {
        title: '简历管理',
        bread: []
      }
    },
    {
      path: '/comments',
      name: 'comments',
      component: () => import('_v/comments/index.vue'),
      meta: {
        title: '留言管理',
        bread: []
      }
    },
    {
      path: '/setting',
      name: 'setting',
      component: () => import('_v/setting/index.vue'),
      meta: {
        title: '系统管理',
        bread: []
      }
    }
  ]
}
