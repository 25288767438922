import Vue from 'vue'
import Router from 'vue-router'
import common from './common'
import app from './app'
import Nprogress from 'nprogress'
import { SITE_NAME } from '@/config/app'
import { getToken } from '@/libs/storage'
Vue.use(Router)

const route = new Router({
  routes: [
    ...common,
    app,
    {
      path: '*',
      component: () => import(/* webpackChunkName: "common/404" */'_v/common/404.vue'),
      meta: {
        title: '找不到页面'
      }
    }
  ]
})
/**
 * 路由前置操作
 */
route.beforeEach((to, from, next) => {
  Nprogress.start()
  document.title = to.meta.title ? to.meta.title + ' - ' + SITE_NAME : SITE_NAME
  const token = getToken()
  if (!token && to.name === 'home') {
    next()
  } else if (!token && to.name !== 'home') {
    next('/')
    Nprogress.done()
  } else if (token && to.name === 'home') {
    next('/dashboard')
    Nprogress.done()
  } else {
    next()
  }
})

/**
 * 路由后置操作
 */
route.afterEach((to, from) => {
  Nprogress.done()
})

export default route
