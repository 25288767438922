import axios from 'axios'
import { Message } from 'element-ui'
import store from '_s'
import Nprogress from 'nprogress'
import { BASE_URL } from '@/config/app'

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 100000,
  withCredentials: true,
  headers: {
    'X-Custom-Header': 'From BasenByte Admin'
  }
})

instance.interceptors.request.use(function (config) {
  Nprogress.start()
  return config
})
instance.interceptors.response.use(function (response) {
  const data = response.data.content
  const code = response.data.code
  const message = response.data.message
  Nprogress.done()
  // 请求返回正常数据
  if (code === 200) {
    return data
  } else if (code === 401) {
    setTimeout(() => {
      store.dispatch('user/clearUserInfo').then(r => {})
      window.location.href = '/#/'
    }, 300)
  } else if (code === 402) {
    window.location.href = '/#/forbidden'
  } else {
    Message.error(message)
    return Promise.reject(message)
  }
}, function (error) {
  console.error(error)
  Nprogress.done()
  return Promise.reject(error)
})

export default instance
