export const cl = (text) => {
  console.log(text)
}
/**
 * 获取文件名后缀
 * @param filename string 文件名，可以带路径
 * @return string/false 后缀，没有后缀时返回false
 */
export const getSuffix = function (filename) {
  const index = filename.lastIndexOf('.')
  return index === -1 ? '' : filename.substr(index + 1)
}
