import { setToken } from '@/libs/storage'
import { userInfo } from '@/request/modules/user'
export default {
  namespaced: true,
  state: () => ({
    userInfo: {}
  }),
  mutations: {
    SET_USERINFO (state, userInfo) {
      state.userInfo = userInfo
    },
    CLEAR_USERINFO (state) {
      state.userInfo = {}
    }
  },
  actions: {
    setUserInfo ({ commit }) {
      userInfo({}).then(resp => {
        commit('SET_USERINFO', resp)
        setToken(new Date().getTime().toString())
      }).catch(() => {})
    },
    clearUserInfo ({ commit }) {
      setToken('')
      commit('CLEAR_USERINFO')
    }
  },
  getters: {
    userInfo (state) {
      return state.userInfo
    }
  }
}
